<template lang="">
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="p-0 filter-card rounded-lg w-100">
                    <b-col cols="12">
                        <h3 class="ml-1 mb-2">Filters</h3>
                    </b-col>
                    <b-col 
                        cols="4" 
                    >
                        <v-select
                            v-model="filter.status"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :options="statuses"
                            class="w-100 mb-0"
                            :reduce="(val) => val.name"
                            placeholder="Status"
                            :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
                        >
                            <template v-slot:option="option">
                            {{ $i18n.locale == "ar" ? option.name_local : option.name }}
                            </template>
                            <template #selected-option="{ name, name_local }">
                              <div style="display: flex; align-items: baseline">
                                <strong v-if="$i18n.locale == 'ar'">{{ name_local }}</strong>
                                <strong v-else>{{ name }}</strong>
                              </div>
                            </template>
                            <template #no-options>{{ $t("noMatching") }}</template>
                        </v-select>
                    </b-col>
                </b-card>
            </b-col>
        </b-row>
        <b-card class="w-100 d-flex rounded-lg">
            <center v-if="load">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </center>
            <b-table 
                :items="items.data" 
                :fields="fields" 
                striped 
                responsive
                v-else
            >
              <template #cell(sender)="data">
                <div>
                  {{data.item.sender.fname +' '+ data.item.sender.lname}}
                </div>
              </template>
              <template #cell(status)="data">
                <div>
                  <b-badge
                    pill
                    :variant="handleStatusColor(data.item.status)"
                  >
                    {{ data.item.status }}
                  </b-badge>
                </div>
              </template>
              <template #cell(actions)="data">
                  <div class="d-flex justify-content-start align-items-center">
                      <b-button
                          class="btn rounded-pill mr-1 px-1 py-50"
                          style="font-size: .9rem"
                          variant="primary"
                          @click="requestAction(data.item, 1)"
                          v-if="hasPermission('Confirm Requests')"
                      >
                          Confirm
                      </b-button>
                      <b-button
                          class="btn rounded-pill px-1 py-50"
                          style="font-size: .9rem"
                          variant="primary"
                          v-if="hasPermission('Reject Requests')"
                          @click="requestAction(data.item, 0)"
                      >
                          Reject
                      </b-button>
                  </div>
              </template>
            </b-table>
            <b-row>
                <b-col md="12" class="d-flex justify-content-center">
                    <b-pagination
                        v-model="items.current_page"
                        :total-rows="items.total"
                        :per-page="items.per_page"
                        @change="onPageChange"
                    ></b-pagination>
                </b-col>
            </b-row>

        </b-card>
    </div>
</template>
<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import {
  BTable,
  BButton,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BAvatar,
  VBTooltip,
  BLink,
  BPagination,
  BSpinner
} from "bootstrap-vue";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
export default {
  name: "courses",
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BCardText,
    BLink,
    ToastificationContent,
    vSelect,
    BPagination,
    BSpinner
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  watch: {
    "filter.status"(newVal) {
      this.$store.dispatch("requests/list", this.filter);
    }
  },
  computed: {
    ...mapGetters({
      items: "requests/items",
      load: "requests/load"
    }),
    id() {
      return this.$route.query.id ? this.$route.query.id : null;
    }
  },
  data() {
    return {
      filter: {
        status: null
      },
      trashOptions: [
        { id: 0, name: "Active" },
        { id: 1, name: "Inactive" }
      ],
      statuses:[
        {name: 'new'},
        {name: 'confirmed'},
        {name: 'rejected'},
      ],
      fields: [
        { key: "sender", label: "User" },
        { key: "data", label: "Request" },
        { key: "msg", label: "Description" },
        { key: "status", label: "Status" },
        "Actions"
      ]
    };
  },
  methods: {
    init() {
      this.$store
        .dispatch("requests/list", { ...this.filter })
        .then(_ => {});
    },
    requestAction(item, confirm){
      const payload = {
        model_id: item.model_id,
        sender_id: item.sender_id,
        confirm: confirm
      }
      this.$store.dispatch("requests/requestAction", payload).then(_=>{
        this.init(); 
        this.$swal({
            icon: 'success',
            title: confirm == 1 ? 'Confirmed!' : 'Rejected!',
            confirmButtonColor: '#E84185',
        })
      }).catch(error => {
          if (error.response) {
          this.$swal({
              icon: 'error',
              title: `<h4>${error.response.data.error}</h4>`,
              showConfirmButton: true,
              confirmButtonColor: '#E84185',
              allowOutsideClick: true
          });
          }
      });
    },
    handleStatusColor(item){
      if(item == 'confirmed'){
        return 'success'
      }else if(item == 'new'){
        return 'info'
      }else{
        return 'danger'
      }
    },
    onPageChange(page) {
      this.$store.dispatch("requests/list", {
        ...this.filter,
        page: page
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style lang="scss">
</style>